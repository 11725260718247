import React from 'react'
import MainLayout from '../layouts/MainLayout'
import Error404View from '../views/Error404View'

const Error404Page = () => {
  return (
    <MainLayout>
      <Error404View />
    </MainLayout>
  )
}

export default Error404Page
