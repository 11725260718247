import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import Error404Page from '../ui/pages/Error404Page'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/en.json'
import { getLocaleFromPathname } from '../libs/i18n'

const Page = props => {
  // 404 page is special because it get's renedered on *every* non-matching URL,
  // so it doesn't make sense to have separate routes for /404 and /de/404.
  //
  // What we want to do is render errors related to english pages in english and
  // errors related to german pages in german, but using always this same Page component
  // (because this is the one Gatsby is going to use on case of route mismatch).
  //
  // To that end, we try to extract the intended lang from the URL and use that as locale.
  //
  const locale = getLocaleFromPathname(props.location)
  return (
    <>
      <HelmetComponent
        title={metaTags.indexTitle}
        description={metaTags.index}
      />
      <AppWrapper lang={locale}>
        <Error404Page {...props} />
      </AppWrapper>
    </>
  )
}
export default Page
